/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import constants from '../constants';
import CommManager from '../CommManager';

Vue.use(Vuex);

const commManager = new CommManager();
const location = window && window.location;
const originURL = location && location.origin;
const paramsUrl = location && location.search;
const searchParams = new URLSearchParams(paramsUrl);
const isIOS = ((navigator.userAgent.match(/(iPhone|iPad|iPod|Mac)/i)) != null);

export default new Vuex.Store({
	state: {
		carouselImages: [],
		currentRaffleData: null,
		enableSounds: false,
		initializedApp: false,
		isIOS,
		lastRaffleShown: null,
		location,
		originURL,
		previousRaffleData: null,
		raffleInProcess: false,
		searchParams,
		showAnticipation: false,
		showDraw: false,
		standaloneMode: true,
		startRaffle: null,
		upcomingDraw: [],
		error: {
			liveDraw: null,
			upcomingDraw: null,
		},
	},
	getters: {
		parsedUpcomingDraws(state) {
			return state.upcomingDraw;
		},
		firstUpcomingDraws(state) {
			return state.upcomingDraw.slice(0, 3);
		},
		currentDraw(state) {
			return state.currentRaffleData;
		},
		previousRaffleData(state) {
			return state.previousRaffleData;
		},
		lastRaffleShown(state) {
			return state.lastRaffleShown;
		},
		caroulselImages(state) {
			return state.carouselImages;
		},
		startRaffle(state) {
			return state.startRaffle;
		},
		showDraw(state) {
			return state.showDraw;
		},
		showAnticipation(state) {
			return state.showAnticipation;
		},
		getRaffleInProcess(state) {
			return state.raffleInProcess;
		},
		getErrorLiveDraw(state) {
			return !state.error.liveDraw;
		},
		getErrorUpcomingDraw(state) {
			return !state.error.upcomingDraw;
		},
		getErrors(state) {
			return (!state.error.upcomingDraw && !state.error.liveDraw);
		},
		statusApp(state) {
			return (state.showDraw);
		},
		standaloneMode() {
			return true;
		},
		getStatusSounds(state) {
			return state.enableSounds;
		},
		gameTypeAvailable(state) {
			// eslint-disable-next-line quotes
			return JSON.parse(state.searchParams.get("gameType"));
		},
		isIOS(state) {
			return state.isIOS;
		},
	},
	mutations: {
		setInitializedApp(state, param) {
			state.initializedApp = param;
		},
		setStartRaffle(state, param) {
			state.startRaffle = param;
			if (param) {
				state.showAnticipation = false;
				state.showDraw = true;
			}
		},
		setStartRecoveryRaffle(state, param) {
			state.showAnticipation = false;
			state.showDraw = true;
			state.startRaffle = param;
		},
		setPreviousRaffleData(state, param) {
			state.previousRaffleData = param;
		},
		setRaffleInProcess(state, param) {
			state.raffleInProcess = param;
		},
		setCaroulselImages(state, images) {
			state.carouselImages = images;
		},
		setLastRaffleShown(state, param) {
			state.lastRaffleShown = param;
		},
		setShowDraw(state, param) {
			state.showDraw = param;
		},
		setShowAnticipation(state, param) {
			state.showAnticipation = param;
			state.showDraw = !param;
		},
		setStatusSounds(state, param) {
			state.enableSounds = param;
		},
		clearErrorsInData(state) {
			state.errorMessage = '';
		},
		setErrorLive(state, param) {
			state.error.liveDraw = param;
		},
		setIsTv(state) {
			const origin = state.originURL;
			const param = origin.split('tv');
			const isTv = (param && param.length && param.length >= 2) || false;

			state.enableSounds = isTv;
		},
		setSoundParameterUrl(state) {
			// eslint-disable-next-line quotes
			const activeSound = JSON.parse(state.searchParams.get("sound"));

			if (activeSound !== null) {
				state.enableSounds = !!activeSound;
			}
		},
	},
	actions: {
		initializeApp(context, urlParams) {
			commManager.initialize(this, urlParams);

			context.commit(constants.SET_INITIALIZED_APP, true);
			context.commit(constants.SET_IS_TV);
			context.commit(constants.SET_SOUND_PARAMETER_URL);
		},
		drawCompleted(context) {
			commManager.getImagensForCarousel();

			context.commit(constants.SET_RAFFLE_IN_PROCESS, false);
			context.commit(constants.SET_SHOW_DRAW, false);
			context.commit(constants.SET_START_RAFFLE, null);
		},
		drawInProcess(context) {
			context.commit(constants.SET_RAFFLE_IN_PROCESS, true);
		},
		showAnticipation(context, param) {
			context.commit(constants.SET_SHOW_ANTICIPATION, param);
		},
		onModuleStatusChanged(context, param) {
			const message = param.data;

			if (window.parent && message) {
				window.parent.postMessage(message, '*');
			}
		},
		statusChangeSounds(context, param) {
			context.commit('setStatusSounds', param);
			// required to external games
			window.initStatusSounds = param;
		},
	},
});
