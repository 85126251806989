/* eslint-disable no-plusplus */
export default class CommManager {
	constructor() {
		this.initialized = false;
		this.store = null;

		this.liveRaffleData = {};
		this.currentDraw = {};
		this.oldDrawsRaffled = [];
		this.gameIdForCarousel = null;

		// Set listeners and timers
		this.listenerLiveRaffleData = null;
		this.listenerGetImagen = null;
		this.timerLiveRaffle = 2000;
		this.timerDefault = 1000;
		this.fetchingPreviousData = false;

		// Connection DATA
		this.systemId = 15;
		this.clientId = 864; // Prod: 2848 - 864 (imagens); // Develop: 92 - 99 - 32
		this.gameType = null;
		this.urlServices = 'https://extapp.gwsvc.de/api/ApuracaoRodada/Sorteios';
		this.authData = JSON.stringify({
			'Sistema_ID': this.systemId,
			'Cliente_ID': this.clientId,
		});

		// gameIs and number of balls per games
		this.totalBallsByGameType = {
			8888: 1,
			7777: 20,
			6666: 6,
			5555: 5,
			2525: 15,
			0: 5,
		};
	}

	initialize(store, urlParams) {
		this.initialized = true;
		this.store = store;
		this.setUrlParams(urlParams);

		const gameIds = Object.keys(this.totalBallsByGameType);
		const gameId = gameIds[Math.floor(Math.random() * gameIds.length)];

		const getRecursiveImagen = (sntTipoJogo) => {
			if (this.gameIdForCarousel !== null) { return; }

			this.getImagensForCarousel(sntTipoJogo)
				.then((result) => {
					if (!result && gameIds.length > 0) {
						const index = gameIds.indexOf(sntTipoJogo);
						gameIds.splice(index, 1);
						console.log(gameIds);
						const newGameId = gameIds[Math.floor(Math.random() * gameIds.length)];

						getRecursiveImagen(newGameId);
					}
				});
		};

		// Start Requests
		this.dispatchRequestLiveRaffle(true);
		this.listenerGetImagen = setTimeout(() => {
			clearTimeout(this.listenerGetImagen);

			getRecursiveImagen(gameId);
		}, this.timerLiveRaffle);
	}

	/**
	 * It takes a string, removes the first and last character if they are slashes, splits the string into
	 * an array, and assigns the first element of the array to the variable `gameType`
	 * @param urlParams - The URL parameters that are passed to the game.
	 * @returns the value of the variable `this.authData`
	 */
	setUrlParams(urlParams) {
		if (typeof urlParams !== 'string') { return; }

		const url = urlParams;
		let params = url.indexOf('/') === 0 ? url.substring(1) : url;

		const lengthURL = params.length - 1;
		params = (params.lastIndexOf('/') === lengthURL) ? params.substring(0, lengthURL) : params;
		params = params.split('/');

		// validate custom game to show
		if (params?.length > 0 && typeof params[0] === 'number') {
			[this.gameType] = params;
		}

		if (this.gameType) {
			this.authData = JSON.stringify({
				'Sistema_ID': this.systemId,
				'Cliente_ID': this.clientId,
				'gameType': this.gameType,
			});
		}
	}

	dispatchRequestLiveRaffle(retryMode) {
		this.listenerLiveRaffleData = setTimeout(() => {
			clearTimeout(this.listenerLiveRaffleData);

			this.getLiveRaffle()
				.then((returnedData) => {
					if (returnedData) {
						this.liveRaffleData = returnedData;
						this.parseLiveDraws();
					}
				});
		}, (!retryMode) ? this.timerLiveRaffle : this.timerDefault);
	}

	getLiveRaffle() {
		const urlBase = `${this.urlServices}/Live`;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: this.authData,
			redirect: 'follow',
			cache: 'no-store',
		};

		const returnedData = fetch(urlBase, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result && result.games) {
					this.dispatchRequestLiveRaffle(false);
					return result;
				}

				// Return error
				console.error('LIVE >>> Error in data...');
				this.dispatchRequestLiveRaffle(true);
				return false;
			})
			.catch((error) => {
				console.error('Catch Error', error);
				this.dispatchRequestLiveRaffle(true);

				return false;
			});

		return returnedData;
	}

	getPreviousResults(timestamp, gameType) {
		if (this.fetchingPreviousData) { return; }

		const time = timestamp?.split(' ');
		const date = time && time[0];
		const urlBase = `${this.urlServices}/ResultadosAnteriores`;
		const body = JSON.stringify({
			'Sistema_ID': this.systemId,
			'Cliente_ID': this.clientId,
			'strData': date,
			'gameType': gameType,
		});

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
			redirect: 'follow',
			cache: 'no-store',
		};

		this.fetchingPreviousData = true;

		fetch(urlBase, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result && result?.draws?.length > 0) {
					const { previousDraws } = result.draws[0];
					let data = {};

					if (previousDraws && previousDraws.length > 1) {
						[, data] = previousDraws;
						data.gameType = gameType;
					}

					this.store?.commit('setLastRaffleShown', data);
					return result;
				}

				// Return error
				console.error('ResultadosAnteriores Service: Error in data...');
				this.store?.commit('setLastRaffleShown', null);
				return false;
			})
			.catch((error) => {
				console.error('ResultadosAnteriores: Error getting data...', error);
				this.dispatchRequestLiveRaffle(true);
			})
			.finally(() => {
				this.fetchingPreviousData = false;
			});
	}

	/**
	 * It sorts the upcomingDraws array by timestamp, and then sets the gameIdForCarousel to the gameType
	 * of the first element in the sorted array
	 * @param upcomingDraws - An array of upcoming draws.
	 */
	nextGameForCarousel(upcomingDraws) {
		const { isIOS } = this.store?.getters;

		const sortedData = upcomingDraws.sort((a, b) => {
			let timeA = a.timestamp;
			let timeB = b.timestamp;

			if (isIOS) {
				let newFormatTimeA = a.timestamp;
				let newFormatTimeB = b.timestamp;
				newFormatTimeA = newFormatTimeA.split(':', 3).join(':');
				newFormatTimeA = newFormatTimeA.split(' ').join('T');
				newFormatTimeB = newFormatTimeB.split(':', 3).join(':');
				newFormatTimeB = newFormatTimeB.split(' ').join('T');
				timeA = newFormatTimeA;
				timeB = newFormatTimeB;
			}

			const aDate = new Date(timeA).getTime();
			const bDate = new Date(timeB).getTime();
			return aDate - bDate;
		});

		if (sortedData.length) {
			const game = sortedData[0];
			this.gameIdForCarousel = game.gameType;
			this.getImagensForCarousel(game.gameType);
		}
	}

	/**
	 * It gets the images for the carousel
	 * @param gameType - The type of game you want to get the images for.
	 * @returns a Promise.
	 */
	getImagensForCarousel(gameType) {
		if (typeof gameType === 'undefined') {
			return false;
		}
		const urlBase = `${this.urlServices}/ImagemDivertidu`;
		const body = JSON.stringify({
			'Sistema_ID': this.systemId,
			'Cliente_ID': this.clientId,
			'sntTipoJogo': gameType,
		});

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
			redirect: 'follow',
			cache: 'no-store',
		};

		const statusResult = fetch(urlBase, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result && result.imagens) {
					this.store?.commit('setCaroulselImages', result.imagens);
					return true;
				}

				console.info('ImagemDivertidu >>> the ClientId has no images to display in the carousel...');
				return false;
			})
			.catch((error) => {
				console.error('Catch Error', error);
				return false;
			});

		return statusResult;
	}

	/**
	 * It parses the data from the API and sets the current draw to be displayed
	 * @returns the current draw.
	 */
	parseLiveDraws() {
		// Definitions
		const previousDraws = [];
		const upcomingDraws = [];
		const delayServer = 4000;
		const { isIOS } = this.store?.getters;
		const data = this?.liveRaffleData?.games;
		let currentDraw;
		let sortedData = [];

		// Get Current time
		let serverTime = this.liveRaffleData && this.liveRaffleData?.serverTimestamp;
		if (isIOS) {
			let newFormat = serverTime.split(':', 3).join(':');
			newFormat = newFormat.split(' ').join('T');
			serverTime = Date.parse(newFormat);
		} else {
			serverTime = Date.parse(serverTime);
		}

		// Loop thru every game
		for (let games = 0; games < data.length; games++) {
			const game = data[games];

			// Order every previous draws
			if (game?.previousDraw) {
				const draw = game.previousDraw;
				draw.gameType = game.gameType;
				draw.payTable = game.payTable;
				draw.serverTime = serverTime;
				draw.delayServer = delayServer;
				draw.upcomingDraw = game.upcomingDraw?.timestamp || '';

				previousDraws.push(draw);
			}

			if (game?.upcomingDraw?.timestamp) {
				const upDraw = {
					gameType: game.gameType,
					timestamp: game.upcomingDraw.timestamp,
				};

				upcomingDraws.push(upDraw);
			}
		}

		// Order matches
		if (previousDraws.length) {
			sortedData = previousDraws.sort((a, b) => {
				let timeA = a.raffleTimestamp;
				let timeB = b.raffleTimestamp;

				if (isIOS) {
					let newFormatTimeA = a.timestamp;
					let newFormatTimeB = b.timestamp;
					newFormatTimeA = newFormatTimeA.split(':', 3).join(':');
					newFormatTimeA = newFormatTimeA.split(' ').join('T');
					newFormatTimeB = newFormatTimeB.split(':', 3).join(':');
					newFormatTimeB = newFormatTimeB.split(' ').join('T');
					timeA = newFormatTimeA;
					timeB = newFormatTimeB;
				}
				const aDate = new Date(timeA).getTime();
				const bDate = new Date(timeB).getTime();
				return bDate - aDate;
			});

			currentDraw = sortedData.shift();
		}

		if (!this.currentDraw || (currentDraw && currentDraw.raffleTimestamp !== this.currentDraw.raffleTimestamp)) {
			// Check if draw was already shown
			let alreadyRaffled = false;
			for (let i = 0; i < this.oldDrawsRaffled.length && !alreadyRaffled; i++) {
				if (this.oldDrawsRaffled[i].gameType === currentDraw.gameType && this.oldDrawsRaffled[i].timestamp === currentDraw.timestamp) {
					alreadyRaffled = true;
				}
			}

			if (!alreadyRaffled && !Number.isNaN(serverTime)) {
				this.currentDraw = currentDraw;
				if (this.oldDrawsRaffled.length) {
					const lastDrawShowed = this.oldDrawsRaffled[this.oldDrawsRaffled.length - 1];
					previousDraws.unshift(lastDrawShowed);
				}
				this.oldDrawsRaffled.push(currentDraw);

				// Set attribute to current Draw
				const raffleTimestamp = currentDraw.timestamp;
				let currentDrawTime = Date.parse(raffleTimestamp);
				if (isIOS) {
					const newFormat = raffleTimestamp.split(' ').join('T');
					currentDrawTime = Date.parse(newFormat);
				}

				this.nextGameForCarousel(upcomingDraws);

				// Validate that the type of game to display is allowed
				const timeIdleMode = (currentDraw.gameType === 7777 || currentDraw.gameType === 0) ? 92000 : 36000;
				const { gameTypeAvailable } = this.store?.getters;
				if ((gameTypeAvailable && gameTypeAvailable !== currentDraw.gameType) || ((currentDrawTime + timeIdleMode) < serverTime) || Number.isNaN(currentDrawTime)) {
					return;
				}

				currentDraw.totalBallsToDraw = this.totalBallsByGameType[currentDraw.gameType];
				currentDraw.raffleParseTimestamp = currentDrawTime;

				if ((serverTime - currentDrawTime) <= delayServer) {
					// Set new raffle
					currentDraw.recovery = false;
					this.store?.commit('setStartRaffle', currentDraw);
				} else {
					// Start recovery draw
					currentDraw.recovery = true;
					this.store?.commit('setStartRecoveryRaffle', currentDraw);
				}

				this.getPreviousResults(currentDraw.raffleTimestamp, currentDraw.gameType);
			}
		}
	}
}
