<template>
	<div class="w100 h100">
		<CarrosselImage
			v-show="showIdle"
			v-bind:initialize="false"
		/>
		<BingoGame
			v-if="showDraw && gameType !== 8888"
			v-bind:drawData="newRaffleData"
			key="bingoComponent"
		/>
		<TimeCerto
			v-else-if="showDraw && newRaffleData && gameType === 8888"
			v-bind:drawData="newRaffleData"
			key="timeCertoComponent"
		/>
	</div>
</template>

<script>
/* eslint-disable no-console */
import BingoGame from '../components/keno.vue';
import TimeCerto from '../components/timeCerto.vue';
import CarrosselImage from '../components/carrossel.vue';

export default {
	name: 'StandaloneView',
	components: {
		CarrosselImage,
		BingoGame,
		TimeCerto,
	},
	data() {
		return {
		};
	},
	computed: {
		newRaffleData() {
			return this.$store.getters.startRaffle;
		},
		gameType() {
			return this.$store.getters.startRaffle?.gameType;
		},
		showDraw() {
			return this.$store.getters.showDraw;
		},
		showIdle() {
			return (!this.$store.getters.showDraw && !this.$store.getters.startRaffle);
		},
		/* keyNewRaffle() {
			return this.$store.getters.startRaffle?.raffleTimestamp || `key_${Math.random()}`;
		}, */
	},
	methods: {
	},
};
</script>
