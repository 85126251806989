<template>
	<div id="bingoPresenterIdle" class="mainIdleContainer">
		<div class="idleContainer">
			<div
				v-show="imagesAvailable"
				class="imageContainer"
				id="imageContainer"
			>
				<img
					id="currentImage"
					:src="currentImageURL"
					alt="imagens carroussel de Divertidu"
					class="image"
				/>
			</div>
			<div
				id="idleText"
				:class='"idleText colorBlue animate__animated animate__headShake animate__infinite animate__slower" + (imagesAvailable ? " idleTextWithImages" : "")'
			>
				Aguarde...
			</div>
		</div>
	</div>
</template>

<script>
import {
	TweenLite,
	Sine,
} from 'gsap';
import '../css/idle.min.css';

export default {
	name: 'CarrosselImage',
	data() {
		return {
			currentId: 0,
			currentImageURL: '',
			timePerImage: 20, // seconds
			images: [],
			gameType: '',
			timeoutNextImage: null,
		};
	},
	computed: {
		imagesAvailable() {
			const imgs = this.$store.getters.caroulselImages;
			if (imgs?.length) {
				this.setImages(imgs);

				return true;
			}

			return false;
		},
	},
	methods: {
		setImages(images) {
			const gameType = images[0].sntTipoJogo;

			this.gameType = gameType;
			this.imagens = images;

			// Set the first image
			this.setCurrentImage();
		},
		setCurrentImage() {
			const img = this.imagens[this.currentId];
			this.currentImageURL = img?.vchUrl;

			if (img.tempoCarrossel && img.tempoCarrossel !== 20) {
				this.timePerImage = img.tempoCarrossel;
			}

			// Start animation
			this.slideInAnimation();
		},
		nextImageId() {
			this.currentId = (this.currentId + 1 >= this.imagens.length) ? 0 : this.currentId + 1;
			this.setCurrentImage();
		},
		slideInAnimation() {
			const image = document.getElementById('currentImage');

			if (image) {
				const callback = () => {
					TweenLite.delayedCall(this.timePerImage, this.slideOutAnimation);
				};

				this.useRAF();
				image.style.left = '110%';
				TweenLite.to(image, 1, {
					ease: Sine.inOut,
					left: '0%',
					onComplete: callback,
				});
			}
		},
		slideOutAnimation() {
			const image = document.getElementById('currentImage');

			if (image) {
				const callback = () => {
					this.nextImageId();
				};

				this.useRAF();
				TweenLite.to(image, 0.5, {
					ease: Sine.inOut,
					left: '-100%',
					onComplete: callback,
				});
			}
		},
		useRAF() {
			// The animations keep running without focus
			TweenLite.ticker.useRAF(false);
			TweenLite.lagSmoothing(0);
		},
	},
};

</script>
