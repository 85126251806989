import Vue from 'vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import store from './store/index';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const routerParams = {
	mode: 'history',
	base: '/',
	routes: [
		{ path: '/:id', redirect: '' },
	],
};

const router = new VueRouter(routerParams);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
