<template>
	<div id="bingoPresenterIdle" class="mainIdleContainer">
		<div class="idleContainer">
			<div id="idleText" class="idleText">
				Aguarde...
			</div>
		</div>
	</div>
</template>

<script>
import '../css/idle.min.css';

export default {
	name: 'StartApp',
	data() {
		return {};
	},
};

</script>
