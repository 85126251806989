const constants = {
	INITIALIZE_APP: 'initializeApp',
	GET_PROXIMOS_SORTEIOS: 'getProximosSorteios',
	GET_SORTEIO_LIVE: 'getSorteioLive',
	SET_INITIALIZED_APP: 'setInitializedApp',
	SET_RAFFLE_IN_PROCESS: 'setRaffleInProcess',
	SET_START_RAFFLE: 'setStartRaffle',
	SET_SHOW_DRAW: 'setShowDraw',
	SET_SHOW_ANTICIPATION: 'setShowAnticipation',
	SET_IS_TV: 'setIsTv',
	SET_SOUND_PARAMETER_URL: 'setSoundParameterUrl',
	STATUS_CHANGED: 'onModuleStatusChanged',

	STATE_APP: 'stateExternalApp',
	INITIALIZED_APP: 'initializedApp',
	VISIBLE_GAME: 'visibleGame',
	DRAW_COMPLETED: 'drawCompleted',
	BINGO_RAFFLING: 'bingoRaffling',
	BINGO_STARTED: 'bingoStarted',
	BINGO_COMPLETED: 'bingoCompleted',
	RODA_STARTED: 'rodaStarted',
	RODA_COMPLETED: 'rodaCompleted',
	IDLE: 'wait',
};

export default constants;
