<template>
	<div
		class="
			mainContainerTimeCerto
			animate__animated
			animate__fadeIn
		"
	>
		<div class="timeCertoContainer">
			<div id="rodaDaFortuna" class="rodaDaFortuna"></div>
		</div>
		<div id="mainMessageTitle" class="error">
			<div id="mainMessageTitleText" class="error">
				{{errorMessage}}
			</div>
		</div>
		<div class="raffleInfoTC RobotoRegularFont">
			<div id="logoTimeCerto" class="logoTimeCerto"></div>
			<div class=" containerAoVivo">Sorteio <span class="aoVivoSpan">AoVivo</span></div>
			<div class="containerDate">
					<div class="backColorDarkBlue">
						<div>Sorteio Atual</div>
						<div v-if="raffleStartTime !== ''">{{raffleStartTime}}hs - {{raffleStartDate}}</div>
					</div>
					<div class="backColorWhite">
						<div>Proximo Sorteio</div>
						<div v-if="nextRaffleTime !== ''">{{nextRaffleTime}}hs - {{nextRaffleDate}}</div>
					</div>
			</div>
			<div class="containerDate">
				<div class="backColorWhiteDate">
					<div>Último Sorteio</div>
					<div v-if="previousRaffleTime !== ''">{{previousRaffleTime}}hs - {{previousRaffleDate}}</div>
				</div>
				<div class="backColorPurple" v-if="lastRaffleShown !== ''">
					{{lastRaffleShown.name}} {{lastRaffleShown.result}}
					<v-img
						width="15%"
						height="100%"
						:id="'team-' + lastRaffleShown.result"
						:src='lastRaffleShown.url'
						class="logo_team"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import constants from '../constants';
import '../css/timeCerto.min.css';

export default {
	name: 'timeCerto',
	props: ['drawData'],
	data() {
		return {
			data: this.drawData,
			errorMessage: '',
			show: true,
			raffleStartDate: '',
			raffleStartTime: '',
			nextRaffleDate: '',
			nextRaffleTime: '',
			previousRaffleDate: '',
			previousRaffleTime: '',
			completedTimeoutId: null,
		};
	},
	beforeDestroy() {
		this.destroyRoda();
	},
	mounted() {
		this.data = this.drawData;

		// const raffleTime = Date.parse(this.data.raffleTimestamp);
		const raffleTime = this.data.raffleParseTimestamp;
		const now = Date.now();
		const timeAnimation = 21000;
		const timeForShowIdle = 458452; // 15000
		const time = this.data?.timestamp?.split(' ');
		const nextTime = this.data?.upcomingDraw?.split(' ');
		const startDate = (time && time[0]) || '';
		const nextDate = (nextTime && nextTime[0]) || '';
		const currentYear = new Date().getFullYear();

		this.raffleStartDate = new Date(startDate).toLocaleDateString('es-AR', { timeZone: 'UTC' }).replace(`/${currentYear}`, '');
		this.nextRaffleDate = new Date(nextDate).toLocaleDateString('es-AR', { timeZone: 'UTC' }).replace(`/${currentYear}`, '');
		this.raffleStartTime = (time && time[1]) || '';
		this.nextRaffleTime = (nextTime && nextTime[1]) || '';

		const dataRaffle = {
			raffled: this.data.raffled,
		};

		if ((now - raffleTime) >= timeAnimation) {
			dataRaffle.animated = false;
			dataRaffle.animationTime = 0;

			if ((now - raffleTime) >= timeAnimation + timeForShowIdle) {
				this.$store.dispatch('drawCompleted');
				return;
			}
		}

		const statusSounds = this.$store.getters.getStatusSounds;
		window.initStatusSounds = statusSounds;
		const rodaDaFortunaContainer = document.getElementById('rodaDaFortuna');

		if (statusSounds && rodaDaFortunaContainer) {
			rodaDaFortunaContainer.click();
		}

		this.startDraw(dataRaffle);
		this.drawCompleted(dataRaffle.animationTime);
	},
	computed: {
		lastRaffleShown() {
			const result = this.$store.getters.lastRaffleShown;
			const name = result?.raffledDescription ? `${result?.raffledDescription} - ` : '';
			let numberId = '';
			let teamLogoUrl = '';
			if (result?.raffled) {
				this.setPreviousResult(result.timestamp);
				[numberId] = result.raffled;
				teamLogoUrl = `../assets/image/team/${numberId}.png`;
			}

			return {
				result: numberId,
				name,
				url: teamLogoUrl,
			};
		},
	},
	methods: {
		startDraw(dataRaffle) {
			window.criarRoda(dataRaffle);
			this.notifyStatus(constants.RODA_STARTED);
		},
		drawCompleted(time) {
			const animationTime = time || 21000;
			const timeForShowIdle = 10000;
			const timeout = animationTime + timeForShowIdle;

			this.completedTimeoutId = setTimeout(() => {
				clearTimeout(this.completedTimeoutId);
				this.completedTimeoutId = null;

				this.$store.dispatch(constants.DRAW_COMPLETED);
				this.notifyStatus(constants.RODA_COMPLETED);
			}, timeout);
		},
		setPreviousResult(timestamp) {
			if (typeof timestamp === 'undefined') {
				return;
			}

			const time = timestamp?.split(' ');
			const previousDate = (time && time[0]) || '';
			const currentYear = new Date().getFullYear();

			this.previousRaffleDate = new Date(previousDate).toLocaleDateString('es-AR', { timeZone: 'UTC' }).replace(`/${currentYear}`, '');
			this.previousRaffleTime = (time && time[1]) || '';
		},
		destroyRoda() {
			const enableSound = window.initStatusSounds;
			this.$store.dispatch('statusChangeSounds', enableSound);

			if (this.completedTimeoutId) {
				clearTimeout(this.completedTimeoutId);
			}

			window.destroyRoda();
			window.muteAll();
		},
		notifyStatus(status) {
			const eventData = {
				type: constants.STATE_APP,
				data: {
					status,
					gameType: this.data.gameType,
					result: this.data.raffled,
					timestamp: this.data.timestamp,
				},
			};

			this.$store.dispatch(constants.STATUS_CHANGED, eventData);
		},
	},
};
</script>
