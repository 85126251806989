<template>
	<v-app p0>
		<v-main>
			<StartApp v-if="!dependenciesLoaded" />
			<StandaloneView />
		</v-main>
	</v-app>
</template>

<script>
import StartApp from './components/startApp.vue';
import StandaloneView from './view/standalone.vue';
import constants from './constants';

import './css/animate.min.css';

export default {
	name: 'App',
	components: {
		StandaloneView,
		StartApp,
	},
	data() {
		return {
			dependenciesLoaded: false,
			dependenciesTimeoutID: null,
		};
	},
	mounted() {
		this.initializeApp();
		this.getDependenciesLoaded();
	},
	methods: {
		getLiveSorteos() {
			this.$store.dispatch(constants.GET_SORTEIO_LIVE);
		},
		getProximosSorteio() {
			this.$store.dispatch(constants.GET_PROXIMOS_SORTEIOS);
		},
		initializeApp() {
			const router = this.$router;
			const currentRoute = router && router.currentRoute;

			if (currentRoute && currentRoute.path) {
				this.$store.dispatch(constants.INITIALIZE_APP, currentRoute.path);
			}
			this.setInitializeState();
		},
		getDependenciesLoaded() {
			this.dependenciesLoaded = (window.assetManager && window.assetManager.assetsLoaded);

			if (!this.dependenciesLoaded) {
				this.dependenciesTimeoutID = setTimeout(() => {
					clearTimeout(this.dependenciesTimeoutID);
					this.dependenciesTimeoutID = null;

					this.getDependenciesLoaded();
				}, 300);
			} else {
				clearTimeout(this.dependenciesTimeoutID);
				this.dependenciesTimeoutID = null;
			}
		},
		setInitializeState() {
			const eventData = {
				type: constants.STATE_APP,
				data: { status: constants.INITIALIZED_APP },
			};

			this.$store.dispatch(constants.STATUS_CHANGED, eventData);
		},
	},
};
</script>
